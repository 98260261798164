@tailwind base;
@tailwind components;
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
@tailwind utilities;
@media (min-width: 1536px) {
    .red-bandwidth{
      max-width: 1274px;
  }
    .container {
        max-width: 1504px;
        /* padding-right: 145px; */
        /* padding-right: 159px;
        padding-left: 136px; */
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .red-bandwidth{
    max-width: 1350px;
}
    .container{
        max-width: 1483px;
        /* max-width: 1339px; */
    }
    .menu-responsive{
        display:flex;
        gap:20px;
        justify-content:center;
        align-items:center;
    }
}
@media (min-width: 640px) {
    .sm\:aspect-h-7 {
        --tw-aspect-h: 3!important;
    }
}

.banner-text{
    font-size: 30px;
    left:27%
}

.menu-responsive{
    display: flex;
    gap:20px;
    align-items: center
   }

.page-box{
  display: none
}

.responsive-ads{
    height: 85px;
}
.box-newest{
  gap:50px
}
.page-item.active .page-link{
  color:white
}
.page-link{
  color:#ab850f;
  border:none;
}
.page-item:last-child .page-link{
  border:none;
}
.pagination{
  gap:5px;
  flex-flow: column;
}
a:hover{
  color:black
}
.texty:hover{
  color:"white"
}
.page-link:hover{
  color:white;
  background-color: #ab850f;
}
.page-item.disabled .page-link{
  color:#ab850f;
}
.page-item.active .page-link{
  background-color: #ab850f;
  border-color: #ab850f;
}
.font-body{
    font-family: "Times New Roman";
}

.link-hover:hover{
  color:#ab850f
}

svg{
  fill: #ab850f;
}

.weather-button{
  width: 39%;
}

/* a:hover{
    text-decoration: underline 3px solid black;
} */

.element-bandwidth:hover{
  text-decoration: underline;
}

.mobile-bandwidth{
  gap:12rem
}

.hover-list:hover{
  text-decoration: underline;
  cursor: pointer;
  color: white;
}

ul li::marker {
  font-size: 18px;
  color: white;
  position: relative;
  padding-top: 5px;
}

.horoskop-content{
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  text-align: left;
  align-items: baseline;
  /* width: 329px; */
  text-align: justify;
  gap: 20px;
}
/* a{
  font-family: "Austin Web";
} */

.link-font{
  font-family: "Merriweather";
  font-size: 14px;
}
.mobile-divider{
  padding-top: 15px;
}
.rhap_container{
  margin-bottom: 15px;
  background-color: black!important;
  margin-top: 15px;
}
.radio-heading{
  display: none;
}
.magazine-box{
  display:"flex";
  gap:38px;
  flex-flow:column;
  align-items:baseline
}

.grid-container, .grid-item {
  display: grid;
  width: 100%;
  align-content: space-between;
}

.grid-container {
  grid-template-columns: 1fr;
}

.max-height-220 {
  max-height: 220px;
  margin-top: 10px;
}

.subscribe{
  background-color:black;
  color:white;
  width:100%;
  padding:13px;
  height:225px;
}
.grid-border{
  border-right: 2px solid #ac8502;
  padding-left: 3px;
  padding-right: 65px;
}

/* .hr:before {
 display: none;
} */

.kitchen-box{
    display:flex;
    width:100%; 
    flex-flow: column;
    align-items:baseline;
    gap:35px
}
.half-box{
  width: 100%;
  display: flex;
  gap:30px;
  justify-content: center;
  /* align-items: center; */
  flex-flow: column;
}

.half-box-special{
  display: none;
}

.column-box{
  flex-flow: column;
}

.image-box{
    width:315px;
    height:80px;
}

.fa-expand:before {
    content: "\f065";
    /* position: absolute; */
    color: black;
    padding-right: 10px;
    /* left: 1164px;
    top: 82%; */
}
.xl\:aspect-h-3{
    --tw-aspect-h: 5;
}
/* .headline{
  width:calc(100vw - 164px),
} */

.horoskop-container{
    display:grid;
    grid:auto / auto;
    margin-top: 2rem;
    grid-row-gap: 20px; 
    grid-column-gap:100px
}

@keyframes animation_down {
    from {background-position-y: 26%; min-height: 353px;}
    to {background-position-y: 50%; min-height: 400px;}
  }

  @keyframes animation_top {
    from {background-position-y: 50%;min-height: 400px;}
    to {background-position-y: 26%;min-height: 353px;}
  }

  @keyframes animation_nav_show {
    from {top: -3px}
    to {top: 0px}
  }

  @keyframes animation_nav_hidden {
    from {top: 64%}
    to {top: 60%}
  }

  .expand-btn{
    color: white;
    font-size: 20px;
    /* position: absolute;
    left: 75%;
    top: 80%; */
  }

  .single-responsive{
    display:flex;
    gap: 16px;
    flex-flow:column;
    align-items:flex-start;
    /* margin-top: 40px;  */
    padding-bottom:30px
  }

  .kuchnia-container{
    display:grid;
    grid:auto / auto;
    margin-top: 2rem;
    grid-row-gap: 20px; 
    grid-column-gap:100px
  }

  .modal{
    background-image: url("./images/baner-reklamowy-auto-serwis.webp");
    background-size: cover;
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
  }

  .popup-content{
    margin: auto;
    background: #fff;
    height: 327px;
    width: 92%!important;
    padding: 5px;
    border: 1px solid #d7d7d7;
  }

  .hover\:text-black:hover{
    color:#967511!important
  }

  .advert-container{
    height: 80px;
    display: none;
  }

  .mobile-header{
    display: none;
  }

  a:hover{
   color:black;
  }

  .mobile-font{
    font-size: 30px;
    line-height: 30px;
    padding-top: 10px;
    font-family: "Redaction";
    font-weight: bold;
  }

  .mobile-header{
    display: none;
  }

  .article-titles{
    color:black;
    /* max-width:338px */
  }

  .video-box{
    display:flex;
    flex-flow:column;
    padding-top:10px;
    align-items:center
  }

  .mobiles-navigation{
    justify-content:center;
    padding-bottom:5rem;
    padding-top:4rem;
    height:3rem;
    font-family:'poppins'
  }

  .element-video{
    width: 100px;
    height: 100px;
  }

  .podcasts-divider{
    margin-top: 0.7rem;
  }

  .live-video{
    max-width: 343px;
  }
  
  .image {
    width: 100%;
    /* animation-name: animation_top;
    animation-duration: 1s; */
    background-size: contain;
    min-height: 152px;
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: 0%;
}
.mobile-nav{
  display: none;
}
.mobile-line{
  display: block;
}

.desktop-line{
  display: none;
}

.mobile-logos{
  display: none;
}

.image-top {
  background-image: url("./images/baner_reklama.jpg");
  width: 100%;
  /* animation-name: animation_top;
  animation-duration: 1s; */
  background-size: contain;
  min-height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: 1%;
}

.subcategory-btn{
  display: block;
}
.article-square{
  width:100px;
  height:100px;
  margin-right:1rem
}

.article-descy{
  font-family: "Roboto";
  font-size: 17px;
  text-align: justify;
  max-width: unset;
}

.category-titles{
  font-size:20px
}

.articles-box{
  flex-flow: column;
  width: 100%;
  box-shadow:none
}

  .slide-switch {
    background-image: url("./images/banner-audi.jpg");
    background-size: cover;
    animation-name: animation_down;
    animation-duration: 1s;
    min-height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: 50%;
  }

  /* .image:active {
    background-image: url("https://adv.adview.pl/ads/uploader/images/2024/8/7/b1230e0c-f8aa-45f8-afcb-eaba9a82bcff/image.png");
    background-size: cover;
    animation-name: example;
    animation-duration: 2s;
    height: 200px;
    background-repeat: no-repeat;
  } */

  .react-slideshow-container .nav:first-of-type{
    display: none;
}

.notfound{
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 30px;
    gap: 10px;
    padding: 30px;
}

.hidden-nav{
  /* border-top: 3px solid #ab850f; */
  display: none;
  /* top:70px;
  font-size:12px; */
}
.search-responsive{
  display: flex;
}

.hover-element{
  background-color: #f8f8f8;
}

.hover-element:hover{
  background-color: #967511;
  color:white;
}

.mobile-logo{
  display: none;
}

.responsive-logo{
  display: none;
}
.menu-logo{
  display: none;
}

.react-slideshow-container .nav:last-of-type{
    display: none;
}
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .hover\:bg-primary-6000:hover{
    background-color: #967511!important;
  }
  .mobile-hidden{
    display: none;
  }
  .image-resp{
    max-width: 429px;
    min-height: unset;
  }
  .mobile-logo-down{
    display: block;
  }
  .box-main{
    display: grid;
    width: 100%;
    padding-bottom: 0px;
    gap: 35px
  }
  .mobile-links{
    display: flex;
    gap:0px;
    flex-flow: row;
  }
  .line-left{
    padding:2px;
    width:35%;
    display: none;
    /* position:absolute; */
    background-color:#ac8502
  }
  .line-right{
    padding:2px;
    width:35%;
    display: none;
    /* position:absolute; */
    background-color:#ac8502
  }
  .box-width-section{
    display: grid;
    width: 100%;
    padding-bottom: 0px;
    padding-top: 5px;
    gap: 35px
  }
  .box-width{
    width: 100%;
  }
  .subcategory-paragraf{
    font-family:"Roboto";
    max-width: 700px;
    font-size: 18px;
  }
  .category-box{
    display: block;
  }
  .divider{
   display: none;
  }
  .padding-box{
    padding-top: 5%;
  }
  /* body{
    overflow-x: hidden;
  } */
  .grid-box{
    display:grid;
    grid:auto / auto;
    gap:20px 50px;
    align-items:end
  }
  .font-mobile{
    font-size:32px;
    text-align:center;
    font-family:"Redaction";
    align-items:center;
    line-height:2.5rem;
    display:flex;
    justify-content:center
  }
  .desktop-container{
    width:100%;
    display:flex;
    justify-content: center;
    gap:46px;
    align-items:center
  }
  .mobile-menu{
   display: none; 
  }
  .additional-menu{
    display: none;
  }
  .kuchnia-box{
    display:grid; 
    justify-content:center;
    grid:auto/ auto;
    align-items:center;
    gap:60px
  }
  .font-slider{
    font-size: 32px;
}

a:focus{
 font-weight: bold;
}

@font-face {
    font-family: Austin Web;
    src: url("./fonts/Austin/AustinNewsDeck-Medium-Reduced.woff.ttf");
  }

  @font-face {
    font-family: Merriweather;
    src: url("./fonts/Merriweather/Merriweather-Light.otf");
  }

@media (min-width:900px ){
  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
  .live-video{
    max-width: 411px;
  }
  .box-newest{
    gap:0px
  }
  .article-square{
    width:60px;
    height:60px;
    margin-right:1rem
  }
  .mobile-bandwidth{
    gap:2rem
  }
  .mobile-divider{
    padding-top: 0px;
  }
  .additional-menu{
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .subcategory-btn{
    display: none;
  }

  .animation-nav{
    animation-name: animation_nav_show;
    animation-duration: 1s;
    position: absolute;
    top:0px;
  }

  .line-left{
    padding:2px;
    width:35%;
    display: block;
    /* position:absolute; */
    background-color:#ac8502
  }

  .rhap_container{
    margin-bottom: 0px;
    background-color: black!important;
    margin-top: 0px;
  }

  .line-right{
    padding:2px;
    width:35%;
    display: block;
    /* position:absolute; */
    background-color:#ac8502
  }

  .font-mobile{
    font-size:40px;
    text-align:center;
    font-family:"Redaction";
    align-items:center;
    line-height:2.5rem;
    display:flex;
    justify-content:center
  }

  .mobile-nav{
    display:flex;
    gap:20px;
    align-items:center
  }

  .mobile-header{
    display: block;
  }

  .link-font{
    font-size: 14px;
  }

  .mobile-menu{
    display: none; 
   }
   .mobiles-navigation{
    justify-content:center;
    padding-bottom:2rem;
    padding-top:2rem;
    height:3rem;
    font-family:'poppins'
  }
  .magazine-box{
    display:flex;
    gap:38px;
    flex-flow:row;
    align-items:baseline
  }

  .desktop-container{
    width:100%;
    display:flex;
    justify-content: space-between;
    gap:46px;
    align-items:center
  }

  .menu-logo{
    display: block;
  }

  .mobile-font{
    font-size: 30px;
    padding-top: 0px;
    font-family: "Redaction";
    font-weight: bold;
  }

  .mobile-line{
    display: none;
  }

  .desktop-line{
    display: block;
  }

  .podcasts-divider{
    margin-top: 1.5rem;
  }

  .article-descy{
    font-family: "Roboto";
    max-width: 379px;
    margin-bottom: 8px;
    text-align: justify;
    font-size: 16px;
  }

  .category-titles{
    font-size:16px
  }

  .text-hover:hover{
    color:#967511
  }

  .video-box{
    display:flex;
    flex-flow:column;
    padding-top:150px;
    align-items:center
  }

  .element-video{
    width: 60px;
    height: 60px;
  }
  
  .responsive-logo{
    display: block;
  }
  .page-box{
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .category-box{
    display: flex;
    gap: 20px;
    align-items: center;
}
  .advert-container{
    height: unset;
    display: block;
  }
  .mobile-logos{
    display: block;
  }
  .mobile-header{
    display: block;
  }

  .mobile-logo-down{
    display: none;
  }
  .mobile-hidden{
    display: block;
  }
  .image {
    width: 100%;
    /* animation-name: animation_top;
    animation-duration: 1s; */
    background-size: cover;
    min-height: 353px;
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: 0%;
}
.weather-button{
  width: 24%;
}
.image-top {
  background-image: url("./images/OPTYK_AD_TOP.jpg");
  width: 100%;
  min-height: 180px;
  /* animation-name: animation_top;
  animation-duration: 1s; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: 26%;
}
  .pagination{
    flex-flow: row;
  }
  /* .hr:before {
    content: '';
      display: block;
      position: absolute;
      top: 40px;
      left: 35%;
      margin-left: 10px;
      transform: translateX(-32%);
      bottom: 28px;
      width: 3px;
      background-color: rgb(172, 133, 2);
  } */
  .kitchen-box{
    display:flex;
    width:100%; 
    flex-flow: row;
    align-items:baseline;
    gap:35px
}
.radio-heading{
  display: block;
}
.half-box{
  width: 50%;
  display: flex;
  gap:10px;
  flex-flow: column;
  align-items: unset;
  justify-content: space-between;
  align-self: normal;
}
.half-box-special{
  display: block;
  width: 50%;
}
  .grid-box{
    display:grid;
    grid:auto / auto auto auto;
    gap:20px 50px;
    align-items:end
  }
    .banner-text{
        font-size:57px;
        left:58%
    }
    /* .padding-box{
      padding-top: 53%;
    } */
    .box-main{
      display: flex;
      flex-flow: row;
      width: 100%;
      align-items: flex-start;
      position: relative;
      padding-bottom: 40px;
      padding-top: 20px;
      gap: 34px
    }
    .mobile-links{
      display: none;
    }
    .box-width-section{
      display: flex;
      flex-flow: row;
      width: 100%;
      align-items: baseline;
      position: relative;
      padding-bottom: 0px;
      padding-top: 0px;
      gap: 34px
    }
    .hr {
      /* display: block; */
      position: relative;
      /* width:2px;
      transform:90%;
      height:400px;
      background-color:rgb(172, 133, 2) */
    }
    .box-width{
      width: 100%;
    }
    .subcategory-paragraf{
      max-width: 475px;
      font-size: 18px;
    }
    .column-box{
      flex-flow: row;
    }
    .popup-content{
      margin: auto;
      background: #fff;
      height: 327px;
      width: 32%!important;
      padding: 5px;
      border: 1px solid #d7d7d7;
    }
    .modal{
      background-image: url("./images/baner-reklamowy-auto-serwis.webp");
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 300px;
    }
    .search-responsive{
      display:flex; 
      /* margin-left:auto; */
      align-items:center;
    }
    .hidden-nav{
      /* border-top: 3px solid #ab850f; */
      display: flex;
      top:60px;
      font-size: 12px;
    }
    .subscribe{
      background-color:black;
      color:white;
      width:46%;
      padding:30px;
      height:225px;
    }
    .image-resp{
      max-width: 729px;
      min-height: 429px;
    }
    .kuchnia-box{
        display:grid; 
        justify-content:flex-start;
        grid:auto/auto auto;
        align-items:baseline;
        gap:60px
      }
    .menu-responsive{
      display: flex;
      gap: 20px;
      border-top: 1px solid black;
      width: 100%;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid black;
    }
    .font-slider{
        font-size: 50px;
    }
    .articles-box{
      display:flex;
      flex-flow: row;
      width:100%;
      box-shadow:0px 1px 3px black;
      padding:20px;
      gap:50px;
      justify-content:space-between
    }
    .single-responsive{
        display:flex;
        gap: 20px;
        flex-flow:column;
        align-items:flex-start;
        /* margin-top: 40px;  */
        padding-bottom:30px;
      }
    .responsive-logo{
      display: block;
    }
    .responsive-ads{
        height: 145px;
    }    
    .horoskop-content{
        display: flex;
        flex-flow: column;
        align-items: baseline;
        /* width:538px; */
        text-align: justify;
    }
    .kitchen-content{
      display: flex;
      gap:20px;
      flex-flow: row-reverse;
      align-items: flex-start;
      /* width:538px; */
      text-align: center;
  }
    .image-box{
        width:1209px;
        height:250px;
        padding-top: 42px;
    }

    .kuchnia-container{
        display:flex;
        flex-flow: column;
        row-gap: 20px; 
        column-gap:100px
  }

    .horoskop-container{
      display:grid;
      grid: auto/ auto auto;
      row-gap: 20px; 
      column-gap:100px
    }
}

